// 获取列表
const getListURL = `${API_CONFIG.shopBaseURL}goodsCategory/getGoodsCategoryList`
// 获取租户商品分类列表
const getCategoryCountListURL = `${API_CONFIG.shopBaseURL}goodsCategory/getRegionGoodsCategoryCountList`
// 获取详情
const getDetailURL = `${API_CONFIG.shopBaseURL}goodsCategory/getGoodsCategoryDetail`
// 编辑url
const editURL = `${API_CONFIG.shopBaseURL}goodsCategory/editGoodsCategory`

export {
  getListURL,
  getDetailURL,
  editURL,
  getCategoryCountListURL
}

// import { generateMapByOpts } from 'common/utils'
const serviceTypeOps = [
  {
    text: '否',
    value: 0
  },
  {
    text: '是',
    value: 1
  }
]
// 状态
const statusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '正常',
    value: 0
  },
  {
    text: '关闭',
    value: 1
  }
]

// const statusMap = generateMapByOpts(statusOps)

export {
  statusOps,
  serviceTypeOps
}

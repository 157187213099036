var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goodsCategoryList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          extraParams: _vm.extraParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "返回" },
                  on: { click: _vm.backAction },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "一级分类名称" },
                  model: {
                    value: _vm.searchParams.categoryAlias,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "categoryAlias", $$v)
                    },
                    expression: "searchParams.categoryAlias",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "一级类目" },
                  model: {
                    value: _vm.searchParams.categoryName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "categoryName", $$v)
                    },
                    expression: "searchParams.categoryName",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "状态", options: _vm.statusOps },
                  model: {
                    value: _vm.searchParams.showStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "showStatus", $$v)
                    },
                    expression: "searchParams.showStatus",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "操作人" },
                  model: {
                    value: _vm.searchParams.operatorUserName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "operatorUserName", $$v)
                    },
                    expression: "searchParams.operatorUserName",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "编辑", type: "text", permission: "update" },
                  on: {
                    click: function ($event) {
                      return _vm.edit(scope.row)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { text: "查看", type: "text", permission: "view" },
                  on: {
                    click: function ($event) {
                      return _vm.look(scope.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }